export const SET_LANGUAGE_VERSION = "SET_LANGUAGE_VERSION";
export const API_Data = "API_Data";
export const Retain_Data = 'Retain_data';
export const Retain_City = 'Retain_City';
export const Retain_Payment_Method = 'RetainPaymentMethodData';
export const Retain_Budget = 'BudgetRetainData'
export const Retain_Email = 'RetainEmail';
export const Retain_Whatsapp = 'RetainWhatsapp';
export const Retain_ContactNo = 'RetainContactNo';
export const Loading = 'isLoadingState';
export const Property_Results = 'PropertyResults';
export const Current_Page = 'CurrentPageListing';
export const isEmptyResults = 'GotEmptyResults';
export const isCitizenship = 'IsCitizenshipAvailable';
export const isFirstHandProject = 'isFirstHandProject';
export const isFirstLoading  = 'IsFirstLoading';
export const ResetState = 'ResetState';

export function resetStateWhenLangSelects() {
     return {
          type: ResetState,
          payload: null,
     };
}

export function setLanguageVersion(data) {
    return {
            type: SET_LANGUAGE_VERSION,
            payload: data,
    }
}

export function setIsFirstLoading(data) {
     return {
          type: isFirstLoading,
          payload: data,
     };
}

export function setIsCitizenship(data) {
     return {
          type: isCitizenship,
          payload: data,
     };
}

export function setIsFirstHandProject(data) {
     return {
          type: isFirstHandProject,
          payload: data,
     };
}


export function setIsEmptyResults(data) {
     return {
          type: isEmptyResults,
          payload: data,
     };
}

export function setCurrentPage(data) {
    return {
            type: Current_Page,
            payload: data,
    }
}
export function isLoadingChange(data) {
    return {
            type: Loading,
            payload: data,
    }
}
export function propertyResults(data) {
    return {
            type: Property_Results,
            payload: data,
    }
}
export function storeApiData ( data){
    return {
        type: API_Data,
        payload: data
    }
}

export function storeEmail ( data){
    return {
        type: Retain_Email,
        payload: data
    }
}

export function storeWhatsapp ( data){
    return {
        type: Retain_Whatsapp,
        payload: data
    }
}

export function storeContactNo ( data){
    return {
        type: Retain_ContactNo,
        payload: data
    }
}

export function storePaymentMethod ( data){
    return {
        type: Retain_Payment_Method,
        payload: data
    }
}

export function storeBudget ( data){
    return {
        type: Retain_Budget,
        payload: data
    }
}

export function storeRetainData (data){
    return {
        type: Retain_Data,
        payload: data
    }
}

export function cityRetainData (data){
    return {
        type: Retain_City,
        payload: data
    }
}

