import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Checkbox } from "antd";
import axios from "axios";
import { ReactComponent as Mugla } from "../../assets/img/Mugla.svg";
import { ReactComponent as Istanbul } from "../../assets/img/Istanbul.svg";
import { ReactComponent as Trabzon } from "../../assets/img/Trabzon.svg";
import { ReactComponent as Izmir } from "../../assets/img/Izmir.svg";
import { ReactComponent as Bursa } from "../../assets/img/Bursa.svg";
import { ReactComponent as Ankara } from "../../assets/img/Ankara.svg";
import { ReactComponent as Antalya } from "../../assets/img/Antalya.svg";
import { ReactComponent as Others } from "../../assets/img/Others.svg";
import useWindowSize from "../Hooks/windowSize";
import { connect, useSelector, useDispatch } from "react-redux";
import { cityRetainData, storeApiData } from "../../redux/actions";

function SelectPropertyType(props) {
  const { languageVersion, handleValidation, handleApiData, pageCount } = props;
  const retainCityData = useSelector((state) => state.cityRetainData);
  const dispatch = useDispatch();
  const data = [
    {
      index: 0,
      cityIcon: Istanbul,
      cityName: languageVersion.istanbul,
      isChecked: false,
    },
    {
      index: 1,
      cityIcon: Ankara,
      cityName: languageVersion.ankara,
      isChecked: false,
    },
    {
      index: 2,
      cityIcon: Izmir,
      cityName: languageVersion.izmir,
      isChecked: false,
    },
    {
      index: 3,
      cityIcon: Antalya,
      cityName: languageVersion.antalya,
      isChecked: false,
    },
    {
      index: 4,
      cityIcon: Bursa,
      cityName: languageVersion.bursa,
      isChecked: false,
    },
    {
      index: 5,
      cityIcon: Trabzon,
      cityName: languageVersion.trabzon,
      isChecked: false,
    },
    {
      index: 6,
      cityIcon: Mugla,
      cityName: languageVersion.mugla,
      isChecked: false,
    },
    {
      index: 7,
      cityIcon: Others,
      cityName: languageVersion.other,
      isChecked: false,
    },
  ];
  const [select, setSelect] = useState(false);
  const [arr, setArr] = useState(data);
  const [width, height] = useWindowSize();
  const [other, setOther] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleCheckbox = () => {
    setSelect(!select);
  };
  useEffect(() => {
    handleValidation(checked);
  }, [checked]);
  const handleOnClick = async (val) => {
    const newArr = arr;
    Object.assign(newArr[val], { isChecked: !newArr[val].isChecked });
    const checkedCities = newArr
    .filter(city => city.isChecked)
    .map(city => city.cityName);
    handleApiData(checkedCities, pageCount);
    props.setStateChange();
    setArr(newArr);
    await dispatch(cityRetainData(newArr));
  };

  useEffect(() => {
    if (retainCityData.length > 0) {
      setArr(retainCityData);
      setChecked(true);
    }
  }, []);

  return (
    <div className="main-select-step">
      <div className="main-content">
        <div
          className={`${
            languageVersion.id === 1
              ? "main-direction select-step-box"
              : "select-step-box"
          }`}
        >
          <Row
            className="select-your-type"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {width >= 320 && width < 767
              ? languageVersion.step_2_select
              : languageVersion.step_2_select_web}
            &nbsp;
            <span
              className="quick-property-step-heading"
              style={{
                fontFamily:
                  languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
              }}
            >
              {languageVersion.step_3_city}
            </span>
          </Row>
          <Row
            className="mobile-quick-property-description"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {languageVersion.step_3_mobile_description}
          </Row>
          <Row
            className="quick-property-description"
            justify="start"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {" "}
            {languageVersion.step_3_description}
          </Row>
          <Row
            className="interested-property"
            style={{
              fontFamily: languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
            }}
          >
            {width >= 320 && width < 767
              ? languageVersion.step_3_choose
              : languageVersion.step_3_mobile_description}
          </Row>
          <Row
            style={{
              overflow: width >= 320 && width < 767 ? "scroll" : null,
              height: width >= 320 && width < 767 ? "175px" : null,
            }}
            className="select-step-row"
          >
            {arr.map((city, index) => {
              return (
                <>
                  <Col
                    style={{
                      marginRight:
                        width >= 320 && width < 767 && index === 7
                          ? "35%"
                          : null,
                      backgroundColor:
                        width >= 320 && width < 767 && city.isChecked === true
                          ? "#FF810F"
                          : null,
                      border: city.isChecked ? "#FF810F 1.5px solid" : null,
                    }}
                    className={`
                    ${
                      width > 900 && width < 1025
                        ? "ipad-pro "
                        : "choose-step-box"
                    }
                    ${
                      width >= 320 && width < 767 && index === 6
                        ? other
                          ? null
                          : "Commercial"
                        : index === 5
                        ? other
                          ? null
                          : "hotel"
                        : index === 7 && other === true
                        ? "other"
                        : null
                    }`}
                    onClick={() => {
                      handleOnClick(index);
                      if (city.isChecked === true) {
                        setChecked(true);
                      } else if (city.isChecked === false) {
                        setChecked(false);
                      }
                      if (index == 7) {
                        setOther(true);
                      }
                    }}
                  >
                    <Checkbox
                      className="step-checkbox"
                      checked={city.isChecked}
                    />
                    <div className="type">
                      <div>
                        <city.cityIcon
                          className={`${
                            city.isChecked === true
                              ? "checked-type"
                              : "type-icon"
                          }`}
                        />
                      </div>
                      {/* <img
                        src={city.cityIcon}
                        className={`type-image ${
                          index === 1 ? "ankara" : index === 7 && "other"
                        }`}
                        alt="Family Logo"
                      ></img> */}
                      <div
                        style={{
                          fontFamily:
                            languageVersion.id === 1
                              ? "NotoSansArabic-Regular"
                              : "",
                          color:
                            width >= 320 &&
                            width < 767 &&
                            city.isChecked === true
                              ? "#fff"
                              : city.isChecked === true
                              ? "#FF810F"
                              : null,
                        }}
                        className="choose-option-name"
                      >
                        {city.cityName}
                      </div>
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
          <div className="blur"></div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    languageVersion: state.languageVersion,
  };
};
export default connect(mapStateToProps)(SelectPropertyType);
