import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Button, notification } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import NextArrow from "../../assets/img/NextArrow.svg";
import backarrow from "../../assets/img/backarrow.svg";
import backarrow1 from "../../assets/img/backarrow1.svg";
import {
  isLoadingChange,
  setCurrentPage,
  setIsEmptyResults,
} from "../../redux/actions";
import axios from "axios";

const FooterButtons = (props) => {
  const dispatch = useDispatch();
  const { languageVersion, validate, handleViewMore } = props;
  const { handlePageCountFooter, pageCount } = props;
  const [viewMore, setviewMore] = useState(false);
  const propertyResults = useSelector((state) => state.propertyResults);
  const reduxState = useSelector((state) => state);
  const openNotification = () => {
    let msg = "";
    switch (pageCount) {
      case 1:
        msg = languageVersion.select_something;
        break;
      case 2:
        msg = languageVersion.select_property_type;
        break;
      case 3:
        msg = languageVersion.select_city;
        break;
      case 4:
        msg = languageVersion.choose_a_payment_method;
        break;
      case 5:
        msg = languageVersion.choose_a_payment_method;
        break;
      case 6:
        msg = languageVersion.fill_contact_details;
        break;
      default:
        break;
    }
    const args = {
      message: msg,

      duration: 1,
    };
    notification.open(args);
  };
  // const handleViewMore = () =>{
  //   setviewMore(true)
  // }

  const handleValidate = async () => {
    // (validate===false? pageCount + 1:pageCount
    if (pageCount === 1) {
      handlePageCountFooter(pageCount + 1);
    } else if (
      reduxState.retainData?.filter((item) => item.isChecked == true).length >
        0 &&
      pageCount === 2
    ) {
      handlePageCountFooter(pageCount + 1);
    } else if (
      reduxState.cityRetainData?.filter((item) => item.isChecked == true)
        .length > 0 &&
      pageCount === 3
    ) {
      handlePageCountFooter(pageCount + 1);
    } else if (validate && pageCount === 4) {
      handlePageCountFooter(pageCount + 1);
    } else if (pageCount === 5) {
      handlePageCountFooter(pageCount + 1);
    } else if (validate && pageCount === 6) {
      handlePageCountFooter(pageCount + 1);
    } else if (pageCount === 7) {
      searchApiCall();
      postUserData();
    } else if (pageCount === 8) {
      handlePageCountFooter(pageCount);
    }
    if (
      reduxState.retainData?.filter((item) => item.isChecked == true).length ==
        0 &&
      pageCount === 2
    ) {
      openNotification();
    } else if (
      reduxState.cityRetainData?.filter((item) => item.isChecked == true)
        .length == 0 &&
      pageCount === 3
    ) {
      openNotification();
    } else if (validate == false && pageCount === 4) {
      openNotification();
    } else if (validate == false && pageCount === 6) {
      openNotification();
    }
  };
  const postUserData = () => {
    try {
      axios
        .post(
          "https://admin-qpt.quickpropertyturkey.com/api/users",
          props.postApiParams
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  async function searchApiCall(page = 1) {
    if (page == 1) {
      await dispatch(setIsEmptyResults(false));
    }

    await dispatch(isLoadingChange(true));

    const url = new URL(
      "https://www.viyacitizenshipservices.com/qpt-app-admin/api/search"
    );
    reduxState.retainData
      ?.filter((item) => item.isChecked == true)
      ?.map((item, index) =>
        url.searchParams.append(`propertyType${index}`, item.propertyName)
      );
    reduxState?.cityRetainData
      ?.filter((item) => item.isChecked == true)
      ?.map((item, index) =>
        url.searchParams.append(`city${index}`, item.cityName)
      );
    url.searchParams.append(
      "payment_method",
      reduxState.paymentMethodRetainData
    );
    url.searchParams.append(
      "budget_range",
      reduxState.budgetRetainData.join(",")
    );
    url.searchParams.append("phone_number", reduxState.phone_number);
    url.searchParams.append("whatsapp_number", reduxState.whatsapp_number);
    url.searchParams.append("email_address", reduxState.email_address);
    url.searchParams.append("sales_person_id", 3);
    url.searchParams.append("page", reduxState.page);
    url.searchParams.append("citizenship", reduxState.isCitizenShipEnabled);

    axios
      .get(url, {
        headers: {
          Authorization: "Bearer 1|pvL0pg5Mcwn0dLk7suYZWayY6MUF0nIsimDY4oZi",
          "X-App-Key": "96ecc653-8da1-4e6f-993e-7f61c28f5476",
        },
      })
      .then(async (response) => {
        var allData = response.data;
        if (allData.data.length == 0) {
          await dispatch(setIsEmptyResults(true));
          return;
        } else {
          await dispatch(setIsEmptyResults(false));
          await dispatch(propertyResults(allData));
        }
      })
      .finally(async () => {
        await dispatch(isLoadingChange(false));
        handlePageCountFooter(pageCount + 1);
      });
  }

  const ifLoadingBgGray = reduxState.isLoading
    ? { backgroundColor: "gray" }
    : {};
  const ifLoadingText = reduxState.isLoading
    ? `${languageVersion.loading}`
    : `${languageVersion.view_more} ➝`;
  const ifLoadingTextStep = reduxState.isLoading
    ? `${languageVersion.loading}`
    : `${languageVersion.footer_next}`;
  const ifLoadingLoader = reduxState.isLoading ? (
    <i class="fa fa-refresh fa-spin" style={{ marginRight: "4px" }}></i>
  ) : (
    ""
  );
  const ifPreviousPage =
    reduxState.page > 1
      ? `${languageVersion.previous}`
      : `${languageVersion.footer_back}`;

  return (
    <div className="footer-section">
      <div className="buttons">
        {pageCount > 0 ? (
          <div
            style={{
              color: pageCount === 1 ? "white" : null,
              fontFamily: languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
            }}
            className="back-button-mobile"
            onClick={() => {
              handlePageCountFooter(pageCount === 1 ? 1 : pageCount - 1);
            }}
          >
            {languageVersion.footer_back}
          </div>
        ) : null}

        <div
          onClick={() => {
            handleValidate();
          }}
        >
          {pageCount === 8 ? (
            <Button
              onClick={handleViewMore()}
              className="next-mobile-step-button"
              style={{
                fontSize: languageVersion.id === 2 ? "13px" : null,
                fontFamily:
                  languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
              }}
            >
              {languageVersion.view_more} &nbsp; <img src={NextArrow} />
            </Button>
          ) : (
            <Button
              className="next-mobile-step-button"
              style={{
                fontFamily:
                  languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
              }}
            >
              {languageVersion.footer_mobile_next} &nbsp;{" "}
              <img src={NextArrow} />{" "}
            </Button>
          )}
        </div>

        {pageCount > 0 ? (
          <div
            className="back-button-div"
            onClick={async () => {
              if (pageCount == 8 && reduxState.page > 1) {
                await dispatch(setCurrentPage(reduxState.page - 1));
                return;
              }
              handlePageCountFooter(pageCount === 1 ? 1 : pageCount - 1);
            }}
          >
            {/* ////////////////////////back button on property listing */}
            <Button
              disabled={pageCount === 1 || reduxState.isLoading ? true : null}
              className="back-button"
              style={{
                border:
                  pageCount === 1 || reduxState.isLoading
                    ? "1px solid rgba(0, 0, 0, 0.3)"
                    : null,
                color:
                  pageCount === 1 || reduxState.isLoading
                    ? "rgba(0, 0, 0, 0.3)"
                    : null,
                opacity: pageCount === 1 || reduxState.isLoading ? "0.7" : null,
                fontFamily:
                  languageVersion.id === 1
                    ? "NotoSansArabic-Bold"
                    : "Montserrat-Reguler",
                fontSize:
                  languageVersion.id === 2 && pageCount === 8 ? "12px" : null,
              }}
            >
              <img
                className="backarrow"
                src={
                  reduxState.isLoading || pageCount === 1
                    ? backarrow1
                    : backarrow
                }
                style={{ opacity: pageCount === 1 ? "0.7" : null }}
              />{" "}
              &nbsp;{""} {ifPreviousPage}
            </Button>
          </div>
        ) : null}

        <div
          className="next-step-div"
          onClick={() => {
            handleValidate();
          }}
        >
          {pageCount === 8 ? (
            propertyResults?.data?.length > 0 &&
            reduxState.isEmptyResults == false ? (
              reduxState?.propertyResults?.data?.length >= 10 && (
                <Button
                  onClick={async () => {
                    if (!reduxState.isLoading) {
                      handleViewMore();
                      await dispatch(setCurrentPage(reduxState.page + 1));
                    }
                  }}
                  className="next-step"
                  style={{
                    fontSize: languageVersion.id === 2 ? "11px" : null,
                    fontFamily:
                      languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
                    ...ifLoadingBgGray,
                  }}
                >
                  {ifLoadingLoader}
                  {ifLoadingText}
                </Button>
              )
            ) : null
          ) : (
            <Button
              style={{
                fontSize: languageVersion.id === 2 ? "13px" : null,
                fontFamily:
                  languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
                ...ifLoadingBgGray,
              }}
              className="next-step"
            >
              {ifLoadingLoader}
              {ifLoadingTextStep}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    languageVersion: state.languageVersion,
  };
};
export default connect(mapStateToProps)(FooterButtons);
