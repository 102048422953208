import React from "react";

const Carousel = (props) => {
  const { pageCount } = props;

  let arr = [];
  for (let i = 1; i < 8; i++) {
    if (pageCount === i) {
      arr.push(
        <div
          className="carousel"
          style={{
            width: "24px",
            height: "8px",
            background: "orange",
            borderRadius: "14px",
          }}
        ></div>
      );
    } else {
      arr.push(<div className="carousel" style={{background: pageCount===8 ? "white": "#C4C4C4"}}></div>);
    }
  }
  return (
    <>
      {pageCount < 9 ? <div className="carousel-parent">{arr}</div> : null}
    </>
  );
};

export default Carousel;
