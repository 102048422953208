import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/styles.scss";
import { Row, Col, Checkbox, Button } from "antd";
import { ReactComponent as Villa } from "../../assets/img/Villa.svg";
import { ReactComponent as Commercial } from "../../assets/img/Commercial.svg";
import { ReactComponent as Industrial } from "../../assets/img/Industrial.svg";
import { ReactComponent as Office } from "../../assets/img/Office.svg";
import { ReactComponent as Land } from "../../assets/img/Land.svg";
import { ReactComponent as HotelApartments } from "../../assets/img/HotelApartments.svg";
import { ReactComponent as Apartment } from "../../assets/img/Apartments.svg";
import { ReactComponent as Others } from "../../assets/img/Others.svg";
import axios from "axios";
import useWindowSize from "../Hooks/windowSize";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  setIsFirstHandProject,
  storeApiData,
  storeRetainData,
} from "../../redux/actions";
import { setIsCitizenship } from "./../../redux/actions";

const SelectPropertyType = (props) => {
  // const { pageCount } = props;
  // const myState = useSelector((state)=>state.dataFromApi);
  const retainData = useSelector((state) => state.retainData);
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const {
    languageVersion,
    handleValidation,
    handleApiData,
    ApiData,
    pageCount,
    paramsData,
  } = props;
  const data = [
    {
      index: 0,
      propertyIcon: Villa,
      propertyName: languageVersion.villa,
      isChecked: false,
    },
    {
      index: 1,
      propertyIcon: Apartment,
      propertyName: languageVersion.apartments,
      isChecked: false,
    },
    {
      index: 2,
      propertyIcon: Office,
      propertyName: languageVersion.office,
      isChecked: false,
    },
    {
      index: 3,
      propertyIcon: Industrial,
      propertyName: languageVersion.industrial,
      isChecked: false,
    },
    {
      index: 4,
      propertyIcon: Commercial,
      propertyName: languageVersion.commercial,
      isChecked: false,
    },
    {
      index: 5,
      propertyIcon: Land,
      propertyName: languageVersion.land,
      isChecked: false,
    },
    {
      index: 6,
      propertyIcon: HotelApartments,
      propertyName: languageVersion.hotel_apartments,
      isChecked: false,
    },
    {
      index: 7,
      propertyIcon: Others,
      propertyName: languageVersion.other,
      isChecked: false,
    },
  ];

  const [select, setSelect] = useState(false);
  const [arr, setArr] = useState(data);
  const [width, height] = useWindowSize();
  // const [other, setOther] = useState(false);
  const [checked, setChecked] = useState(false);
  //  const [value, setValue] = useState("");

  useEffect(() => {
    if (retainData.length > 0) {
      setArr(retainData);
      setChecked(true);
    }
  }, []);

  const handleOnClick = async (val) => {
    const newArr = arr;
    Object.assign(newArr[val], { isChecked: !newArr[val].isChecked });
    const checkedPrperties = newArr
    .filter(city => city.isChecked)
    .map(city => city.propertyName);
    handleApiData(checkedPrperties, pageCount);
    props.setStateChange();

    
    setArr(newArr);
    // setValue(val)a
    await dispatch(storeRetainData(newArr));
  };
  useEffect(() => {
    handleValidation(checked);
  }, [checked]);



  return (
    <div className="main-select-step">
      <div className="main-content">
        <div
          className={`${
            languageVersion.id === 1
              ? "main-direction select-step-box"
              : "select-step-box"
          }`}
        >
          <Row
            className="select-your-type"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {width >= 320 && width < 767
              ? languageVersion.step_2_select
              : languageVersion.step_2_select_web}
            <span
              className="quick-property-step-heading"
              style={{
                fontFamily:
                  languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
              }}
            >
              {" "}
              {languageVersion.type_property}
            </span>
          </Row>
          <Row
            className="mobile-quick-property-description"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {languageVersion.which_property_mobile}
          </Row>
          <Row
            className="quick-property-description"
            justify="start"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {" "}
            {languageVersion.which_property}
          </Row>
          <Row
            className="interested-property"
            style={{
              fontFamily: languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
            }}
          >
            {languageVersion.real_estate}
          </Row>
          <Row
            style={{
              overflow: width >= 320 && width < 767 ? "scroll" : null,
              height: width >= 320 && width < 767 ? "175px" : null,
            }}
            className={"select-step-row"}
          >
            {arr.map((propertyType, index) => {
              return (
                <>
                  <Col
                    style={{
                      marginRight:
                        width >= 320 && width < 767 && index === 7
                          ? "35%"
                          : null,
                      // marginTop: (width >= 320 && width < 767 && index===6) ? "5%" : "0",
                      marginBottom:
                        width >= 320 && width < 767
                          ? "5%"
                          : width >= 320 && width < 767
                          ? "5%"
                          : "0",
                      backgroundColor:
                        width >= 320 &&
                        width < 767 &&
                        propertyType.isChecked === true
                          ? "#FF810F"
                          : null,
                      border: propertyType.isChecked
                        ? "#FF810F 1.5px solid"
                        : null,
                    }}
                    className={
                      `
                   ${
                     width > 900 && width < 1025
                       ? "ipad-pro "
                       : "choose-step-box"
                   }`
                      //  ${
                      //    width >= 320 && width < 767 && index === 6
                      //      ? other
                      //        ? null
                      //        : "Commercial"
                      //      : index === 5
                      //     //  ? other
                      //     //    ? null
                      //     //    : "hotel"
                      //     //  : index === 7

                      //      : null
                      //  }`
                    }
                    onClick={() => {
                      handleOnClick(index);
                      // setValue(index);
                      if (propertyType.isChecked === true) {
                        setChecked(true);
                      } else if (propertyType.isChecked === false) {
                        setChecked(false);
                      }

                      // if (index == 7) {
                      //   setOther(true);
                      // }
                    }}
                  >
                    <Checkbox
                      className="step-checkbox"
                      checked={propertyType.isChecked}
                    />
                    <div className="type">
                      <div>
                        <propertyType.propertyIcon
                          className={`${
                            propertyType.isChecked === true
                              ? "checked-type"
                              : "type-icon"
                          }`}
                        />
                      </div>
                      <div
                        style={{
                          padding: languageVersion.id === 1 ? "2px" : null,
                          fontFamily:
                            languageVersion.id === 1
                              ? "NotoSansArabic-Regular"
                              : "",
                          color:
                            width >= 320 &&
                            width < 767 &&
                            propertyType.isChecked === true
                              ? "#fff"
                              : propertyType.isChecked === true
                              ? "#FF810F"
                              : null,
                        }}
                        className="choose-option-name"
                      >
                        {propertyType.propertyName}
                      </div>
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
          <div className="blur"></div>
          <div
            className="checkbox-field-text"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Medium" : "",
              justifyContent: languageVersion.id === 1 ? "normal" : null,
            }}
          >
            <Checkbox
              checked={reduxState.isCitizenShipEnabled == 1 ? true : false}
              onChange={async (e) => {
                //  console.log(`checked = ${e.target.checked}`);
                if (e.target.checked == true) {
                  await dispatch(setIsCitizenship(1));
                } else if (e.target.checked == false) {
                  await dispatch(setIsCitizenship(0));
                }
              }}
              style={{
                marginRight:
                  width >= 320 && width < 767 && languageVersion.id === 1
                    ? "20%"
                    : languageVersion.id === 1
                    ? "10%"
                    : width > 767 && languageVersion.id === 2
                    ? "40px"
                    : null,
              }}
            >
              {languageVersion.citizen}
            </Checkbox>
            <Checkbox
              checked={reduxState.isFirstHandProject == 1 ? true : false}
              onChange={async (e) => {
                if (e.target.checked == true) {
                  console.log(`checked = ${e.target.checked}`);
                  dispatch(setIsFirstHandProject(1));
                } else if (e.target.checked == false) {
                  console.log(`checked = ${e.target.checked}`);
                  dispatch(setIsFirstHandProject(0));
                }
              }}
            >
              {languageVersion.first_hand}
            </Checkbox>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    languageVersion: state.languageVersion,
    paramsData: state.paramsData,
  };
};

export default connect(mapStateToProps)(SelectPropertyType);
