import React from "react";
import { useState, useEffect } from "react";
import validator from "validator";

import { AutoComplete, Row } from "antd";
import { DownOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { connect } from "react-redux";
import axios from "axios";
import FooterButtons from "../Footer";
import { useSelector, useDispatch } from "react-redux";
import { propertyResults, storeContactNo, storeEmail, storeWhatsapp } from "../../redux/actions";
import "react-phone-input-2/lib/high-res.css";
import "../../assets/css/styles.scss";

const ContactDetails = (props) => {
     let one = React.createRef();
  let two = React.createRef();
  let three = React.createRef();
  const dispatch = useDispatch();
  const retainedEmail = useSelector(state => state.email_address);
  const retainContactNo = useSelector(state => state.phone_number);
  const retainWhatsappNo = useSelector(state => state.whatsapp_number);


  const { languageVersion, handleApiData, pageCount, handleValidation,validate, handlePageCountFooter, handleViewMore  } = props;
  const [phoneNumber, setphoneNumber] = useState("");
  const [WhatsappNumber, setWhatsappNumber] = useState("");
  const [EmailInput, setEmailInput] = useState("");
  const [validation, setvalidation] = useState(false);
  const [isStartWritingPhone, setWritingPhone] = useState(false);
  const [isStartWritingWhatsapp, setWritingWhatsapp] = useState(false);
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    if(retainedEmail){
      setEmailInput(retainedEmail);
    }
    if(retainWhatsappNo){
      setWhatsappNumber(retainWhatsappNo);
    }
    if(retainContactNo){
      setphoneNumber(retainContactNo);
    }
  }, [])



  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError(languageVersion.email_vaidation);
    }
  };
  useEffect(() => {
if(phoneNumber.length > 0){
  setWritingPhone(true);

}
if(WhatsappNumber.length > 0){
  setWritingWhatsapp(true);
}
    handleValidation(phoneNumber.length > 8 && WhatsappNumber.length > 8 && validator.isEmail(EmailInput));
    let data = [phoneNumber, WhatsappNumber, EmailInput];
    // if (phoneNumber === "") {
    //   if (EmailInput === "") {
    if ((data = ["", "", ""] && phoneNumber && WhatsappNumber && validator.isEmail(EmailInput))) {
      setvalidation(true);
    }else{
      setvalidation(false)
    }
console.log('=====data===',phoneNumber,WhatsappNumber,EmailInput)
    handleApiData(data, pageCount);
  }, [phoneNumber, WhatsappNumber, EmailInput]);

  return (
       <div className="main-select-step">
            <div className="main-content">
                 <div
                      className={`${
                           languageVersion.id === 1
                                ? "main-direction select-step-box"
                                : "select-step-box"
                      }`}
                 >
                      <Row
                           className="select-your-type"
                           style={{
                                fontFamily:
                                     languageVersion.id === 1
                                          ? "NotoSansArabic-Regular"
                                          : "",
                           }}
                      >
                           {languageVersion.step_6_enter}&nbsp;
                           <span
                                className="quick-property-step-heading"
                                style={{
                                     fontFamily:
                                          languageVersion.id === 1
                                               ? "NotoSansArabic-Bold"
                                               : "",
                                }}
                           >
                                {languageVersion.step_6_contact}
                           </span>
                      </Row>
                      <Row
                           className="mobile-quick-property-description"
                           style={{
                                fontFamily:
                                     languageVersion.id === 1
                                          ? "NotoSansArabic-Regular"
                                          : "",
                           }}
                      >
                           {languageVersion.step_6_mobile_description}
                      </Row>
                      <Row
                           className="quick-property-description"
                           justify="start"
                           style={{
                                fontFamily:
                                     languageVersion.id === 1
                                          ? "NotoSansArabic-Regular"
                                          : "",
                           }}
                      >
                           {" "}
                           {languageVersion.step_6_description}
                      </Row>
                      <Row
                           className="contact-form-mobile"
                           style={{ flexDirection: "column", marginBottom: "40PX" }}
                      >
                           <Row
                                className="choose-budget"
                                style={{
                                     fontFamily:
                                          languageVersion.id === 1
                                               ? "NotoSansArabic-Medium"
                                               : "",
                                }}
                           >
                                {languageVersion.reach_out}
                                <br />
                           </Row>
                           <Row>
                                <label
                                     className="phone-input-label"
                                     style={{
                                          fontFamily:
                                               languageVersion.id === 1
                                                    ? "NotoSansArabic-Regular"
                                                    : "",
                                     }}
                                >
                                     {languageVersion.phone_no}
                                     <PhoneInput
                                             ref={one}
                                             onBlur={() => {
                                                  console.log('one', two.current.focus(), two.current.onFocus(), two.current.autoFocus());
                                                  two.current.onFocus();
                                             }}
                                             returnKeyType = {"next"}
                                             inputProps={{
                                                  ref: one
                                             }}
                                          style={
                                               phoneNumber.length > 8
                                                    ? {
                                                           border: "0px solid #cacaca",
                                                           borderRadius: "5px",
                                                      }
                                                    : isStartWritingPhone
                                                    ? {
                                                           border: " 1px solid red",
                                                           borderRadius: "5px",
                                                      }
                                                    : {}
                                          }
                                          value={phoneNumber}
                                          type="number"
                                          buttonClass="dropdownclass"
                                          country={"tr"}
                                          placeholder="+90"
                                          onChange={async (e) => {
                                               setphoneNumber(e);
                                               await dispatch(storeContactNo(e));
                                          }}
                                     />
                                </label>
                           </Row>
                           <Row>
                                <label
                                     className="phone-input-label"
                                     style={{
                                          fontFamily:
                                               languageVersion.id === 1
                                                    ? "NotoSansArabic-Regular"
                                                    : "",
                                     }}
                                >
                                     {languageVersion.whatsapp_number}
                                     <PhoneInput
                                     inputProps={{
                                        ref: two
                                         }}
                                         onBlur={() => {
                                             console.log('one', three.current.focus());
                                        }}
                                           ref={two}
                                           autoFocus
                                          style={
                                               WhatsappNumber.length > 8
                                                    ? {
                                                           border: "0px solid #cacaca",
                                                           borderRadius: "5px",
                                                      }
                                                    : isStartWritingWhatsapp
                                                    ? {
                                                           border: " 1px solid red",
                                                           borderRadius: "5px",
                                                      }
                                                    : {}
                                          }
                                          value={WhatsappNumber}
                                          country={"tr"}
                                          // defaultMask="+90"
                                          // alwaysDefaultMask={'+90'}
                                          searchPlaceholder={"+90"}
                                          placeholder="+90"
                                          onChange={async (e) => {
                                               setWhatsappNumber(e);
                                               await dispatch(storeWhatsapp(e));
                                          }}
                                     />
                                </label>
                           </Row>

                           <Row>
                                <label
                                     className="phone-input-label"
                                     style={{
                                          fontFamily:
                                               languageVersion.id === 1
                                                    ? "NotoSansArabic-Regular"
                                                    : "",
                                     }}
                                >
                                     {languageVersion.email_address}
                                     <input
                                             ref={three}
                                          style={
                                               !emailError && !EmailInput.length < 5
                                                    ? { border: "1px solid #cacaca" }
                                                    : {
                                                           border: " 1px solid red",
                                                           outline: "1px solid red",
                                                      }
                                          }
                                          value={EmailInput}
                                          //autoComplete="off"
                                          className="email-feild"
                                          type="email"
                                          name="email"
                                          onChange={async (e) => {
                                               validateEmail(e);
                                               setEmailInput(e.target.value);
                                               await dispatch(storeEmail(e.target.value));
                                          }}
                                     />
                                     {/* <h5
                style={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                {emailError}
              </h5> */}
                                </label>
                           </Row>
                      </Row>
                 </div>
            </div>
       </div>
  );
};

const mapStateToProps = (state) => {
  return {
    languageVersion: state.languageVersion,
  };
};
export default connect(mapStateToProps)(ContactDetails);
