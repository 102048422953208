import React, { useState, useEffect } from "react";
import "../../assets/css/styles.scss";
import { Row, Col, Checkbox, Button } from "antd";
import { ReactComponent as AdvancedPayment } from "../../assets/img/AdvancedPayment.svg";
import { ReactComponent as Installment } from "../../assets/img/Installment.svg";
import useWindowSize from "../Hooks/windowSize";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";
import { storePaymentMethod } from "../../redux/actions";

const SelectPropertyType = (props) => {
  const dispatch = useDispatch();
  const paymentMethodRetainData = useSelector(
    (state) => state.paymentMethodRetainData
  );
  const { languageVersion, handleApiData, pageCount, handleValidation } = props;

  const [select, setSelect] = useState(false);
  const [selectedVal, setSelectedValue] = useState();
  const [validation, setvalidation] = useState(false);
  const [width, height] = useWindowSize();

  const data = [
    {
      paymentIcon: AdvancedPayment,
      paymentName: languageVersion.advance,
    },
    {
      paymentIcon: Installment,
      paymentName: languageVersion.installment,
    },
  ];
  const handleCheckbox = () => {
    setSelect(!select);
  };
  const handleOnClick = async (val) => {
    setSelectedValue(val);
    setvalidation(true);
    await dispatch(storePaymentMethod(val));
    // if (selectedVal === 0) {
    //   setvalidation(true);
    // }
    // if (selectedVal === 1) {
    //   setvalidation(true);
    // }
  };

  useEffect(() => {
    if (paymentMethodRetainData == 0 || paymentMethodRetainData == 1) {
      setSelectedValue(paymentMethodRetainData);
      setvalidation(true);
    }
  }, []);

  useEffect(() => {
    handleValidation(validation);
    handleApiData(selectedVal, pageCount);
  }, [selectedVal]);
  return (
    <div className="main-select-step">
      <div className="main-content">
        <div
          className={`${
            languageVersion.id === 1
              ? "main-direction select-step-box"
              : "select-step-box"
          }`}
        >
          <Row
            className="select-your-type"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {width >= 320 && width < 767
              ? languageVersion.step_2_select
              : languageVersion.step_2_select_web}{" "}
            <span
              className="quick-property-step-heading"
              style={{
                fontFamily:
                  languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
              }}
            >
              {languageVersion.step_4_payment}
            </span>
          </Row>
          <Row
            className="mobile-quick-property-description"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {languageVersion.step_4_description}
          </Row>
          <Row
            className="quick-property-description"
            justify="start"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {" "}
            {languageVersion.step_4_description}
          </Row>
          <Row
            className="interested-property"
            style={{
              fontFamily: languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
            }}
          >
            {languageVersion.step_4_method}
          </Row>
          <Row
            className="select-step-row"
            style={{
              justifyContent: "normal",
              marginRight: width >= 320 && width < 767 ? "4%" : null,
            }}
          >
            {data.map((payment, index) => {
              return (
                <>
                  <Col
                    style={{
                      marginRight: width >= 320 && width < 767 ? "6%" : null,
                      backgroundColor:
                        width >= 320 && width < 767 && selectedVal === index
                          ? "#FF810F"
                          : null,
                      border:
                        selectedVal === index ? "#FF810F 1.5px solid" : null,
                    }}
                    className={`${
                      width > 900 && width < 1025
                        ? "ipad-pro "
                        : "choose-step-box"
                    }
                    `}
                    onClick={() => {
                      handleOnClick(index);
                    }}
                  >
                    <Checkbox
                      className="step-checkbox"
                      checked={selectedVal === index ? true : false}
                    />
                    <div className="type">
                      <div>
                        <payment.paymentIcon
                          className={`${
                            selectedVal === index ? "checked-type" : "type-icon"
                          }`}
                        />
                      </div>

                      <div
                        className="choose-option-name"
                        style={{
                          fontFamily:
                            languageVersion.id === 1
                              ? "NotoSansArabic-Regular"
                              : "",
                          color:
                            width >= 320 && width < 767 && selectedVal === index
                              ? "#fff"
                              : selectedVal === index
                              ? "#FF810F"
                              : null,
                        }}
                      >
                        {payment.paymentName}
                      </div>
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    languageVersion: state.languageVersion,
  };
};
export default connect(mapStateToProps)(SelectPropertyType);
