import { Col } from "antd";
import React from "react";
import Skeleton from "react-loading-skeleton";
import useWindowSize from "../Hooks/windowSize";
import "react-loading-skeleton/dist/skeleton.css";

export default function SkeletonCard({count =0}) {
  const [width] = useWindowSize();
  return (
    new Array(count).fill().map((e,i) => <Col
    // lg={12}
    // md={12}
    // sm={24}
    style={{
      fontFamily: "Mizan-AR LT-Bold",
      alignItems: "center",
      borderColor: 'lightgray',
      maxHeight: '200px',
       
      //display: index > 3 ? "none" : null,
    }}
    className={`search-property-col `}
  >
    <div className="property-picture">
      <Skeleton height="90%" />
    </div>
    <div style={{padding: width > 320 && width < 768 ? "15px" : "0"}} className="search-property-content">
      <Skeleton count={3} />
    </div>
  </Col>)
  );
}
