import React from "react";
import { useEffect, useState } from "react";
import "../../assets/css/styles.scss";
import { Row, Col, Button } from "antd";
import propertypicture from "../../assets/img/propertypicture.png";
import "react-phone-input-2/lib/style.css";
import locationicon from "../../assets/img/locationicon.svg";
import bed from "../../assets/img/bed.svg";
import bath from "../../assets/img/bath.svg";
import bedroom from "../../assets/img/bedroom.svg";
import sqyard from "../../assets/img/sqyard.svg";
import useWindowSize from "../Hooks/windowSize";
import { connect } from "react-redux";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../loader";
import {
     isLoadingChange,
     propertyResults,
     setCurrentPage,
     setIsEmptyResults,
} from "../../redux/actions";
import { whatsappApi } from "../../services/whatsappapi";
import { SkeletonTheme } from "react-loading-skeleton";
import SkeletonCard from "../SkeletonCard";
const englishJson = require('../../json/english.json');

const typeData = [
     {
      index: 0,
       propertyName: englishJson.villa,
       isChecked: false,
     },
     {
      index: 1,
       propertyName: englishJson.apartments,
       isChecked: false,
     },
     {
      index: 2,
       propertyName: englishJson.office,
       isChecked: false,
     },
     {
      index: 3,
       propertyName: englishJson.industrial,
       isChecked: false,
     },
     {
      index: 4,
       propertyName: englishJson.commercial,
       isChecked: false,
     },
     {
      index: 5,
       propertyName: englishJson.land,
       isChecked: false,
     },
     {
      index: 6,
       propertyName: englishJson.hotel_apartments,
       isChecked: false,
     },
     {
      index: 7,
       propertyName: englishJson.other,
       isChecked: false,
     },
   ];

const cityData = [
     {
       index:1,
       cityName: englishJson.istanbul,
       isChecked: false,
     },
     {
       index:2,
       cityName: englishJson.ankara,
       isChecked: false,
     },
     {
       index: 3,
       cityName: englishJson.izmir,
       isChecked: false,
     },
     {
       index: 4,
       cityName: englishJson.antalya,
       isChecked: false,
     },
     {
       index: 5,
       cityName: englishJson.bursa,
       isChecked: false,
     },
     {
       index: 6,
       cityName: englishJson.trabzon,
       isChecked: false,
     },
     {
       index: 7,
       cityName: englishJson.mugla,
       isChecked: false,
     },
     {
       index: 8,
       cityName: englishJson.other,
       isChecked: false,
     },
   ];

const BestProperties = (props) => {
     const dispatch = useDispatch();
     const [isTextShow, setIsTextShow] = useState(false);
     const { languageVersion, apiParams, viewMore } = props;
     const reduxState = useSelector((state) => state);
     const [width] = useWindowSize();
     const handleProperties = () => {};
     const [isFirst, setIsFirst] = useState(false);

     let allData;
     
     

     async function searchApiCall(page = 1) {
          if (reduxState?.propertyResults?.data?.lenght > 0) {
               await dispatch(setIsEmptyResults(false));
          }

          console.log({
               params: {
                    property_type: reduxState.retainData
                         .filter((item) => item.isChecked == true)
                         .map((item) => typeData[item.index].propertyName),
                    city: reduxState.cityRetainData
                         .filter((item) => item.isChecked == true)
                         .map((item) => cityData[item.index].cityName),
                    payment_method: reduxState.paymentMethodRetainData,
                    budget_range: reduxState.budgetRetainData.join(","),
                    phone_number: reduxState.phone_number,
                    whatsapp_number: reduxState.whatsapp_number,
                    email_address: reduxState.email_address,
                    sales_person_id: 3,
                    page: reduxState.page,
                    ak_first_hand: reduxState.isFirstHandProject,
                    citizenship: reduxState.isCitizenShipEnabled,
               },
          });

          
          const url = new URL(
               "https://www.viyacitizenshipservices.com/qpt-app-admin/api/search"
          );
          reduxState.retainData
               .filter((item) => item.isChecked == true)
               .map((item, index) =>
                    url.searchParams.append(`property_type[${index}]`, typeData[item.index].propertyName)
               );
          reduxState.cityRetainData
               .filter((item) => item.isChecked == true)
               .map((item, index) =>
                    url.searchParams.append(`city[${index}]`, cityData[item.index].cityName)
               );
          url.searchParams.append("payment_method", reduxState.paymentMethodRetainData);
          url.searchParams.append("budget_range", reduxState.budgetRetainData.join(","));
          url.searchParams.append("phone_number", reduxState.phone_number);
          url.searchParams.append("whatsapp_number", reduxState.whatsapp_number);
          url.searchParams.append("email_address", reduxState.email_address);
          url.searchParams.append("sales_person_id", 3);
          url.searchParams.append("page", reduxState.page);
          url.searchParams.append("citizenship", reduxState.isCitizenShipEnabled);
          console.log('======------> URL', url)
               await dispatch(isLoadingChange(true));
          axios.get(url, {
               headers: {
                    Authorization: "Bearer 1|pvL0pg5Mcwn0dLk7suYZWayY6MUF0nIsimDY4oZi",
                    "X-App-Key": "96ecc653-8da1-4e6f-993e-7f61c28f5476",
               },
               timeout: 1000 * 30, // Wait for 5 seconds
          })
               .then(async (response) => {
                    allData = response.data;
                    if (allData.data.length == 0) {
                         await dispatch(setIsEmptyResults(true));
                         return;
                    } else {
                         await dispatch(setIsEmptyResults(false));
                         await dispatch(propertyResults(allData));
                         console.log("RESPONSE======>", allData);
                    }
                    await whatsappApi.sendToWhatsapp(allData.data);
               })
               .finally(async () => {
                    await dispatch(isLoadingChange(false));
               });
     }

     useEffect(() => {
          setTimeout(() => {
               setIsTextShow(true);
          }, 1000)
          if(isFirst){
               searchApiCall(reduxState.page);
          }
          setIsFirst(true);
     }, [reduxState.page]);

     const changeStateToFreshIsEmptyResults = async () => {
          await dispatch(setCurrentPage(1));
          await dispatch(setIsEmptyResults(false));
     };

     useEffect(() => {
          changeStateToFreshIsEmptyResults();
          // ApiData();
          // setdataFromApi(allData);
          searchApiCall();
          return;
          // axios
          //   .get("https://www.viyacitizenshipservices.com/qpt-app-admin/api/search", {
          //     headers: {
          //       Authorization: "Bearer 1|pvL0pg5Mcwn0dLk7suYZWayY6MUF0nIsimDY4oZi",
          //       "X-App-Key": "96ecc653-8da1-4e6f-993e-7f61c28f5476",
          //     },
          //     params: {
          //       property_type: apiParams.propertyTypesArray,
          //       city: apiParams.CityTypeArray,
          //       payment_method: apiParams.PaymentMethodTypeArray,
          //       budget_range: apiParams.budgetRangeArray,
          //       phone_number: apiParams.ContactDetailsArray[0],
          //       whatsapp_number: apiParams.ContactDetailsArray[1],
          //       email_address: apiParams.ContactDetailsArray[2],
          //       sales_person_id: 3,
          //     },
          //   })
          //   .then((response) => {
          //     allData = response.data;
          //     setdataFromApi(allData);
          //     console.log("RESPONSE======>", allData);
          //   });
     }, []);
     return (
          <div className="main-select-step">
               <div className="main-content">
                    <div
                         className={`${
                              languageVersion.id === 1
                                   ? "main-direction select-step-box"
                                   : "select-step-box"
                         }`}
                    >
                         {reduxState?.propertyResults?.data?.length > 0 && <Row
                              className="select-your-type"
                              style={{
                                   fontFamily:
                                        languageVersion.id === 1
                                             ? "NotoSansArabic-Regular"
                                             : "",
                              }}
                         >
                              {languageVersion.step_8_here}
                              <span
                                   className="quick-property-step-heading"
                                   style={{
                                        fontFamily:
                                             languageVersion.id === 1
                                                  ? "NotoSansArabic-Bold"
                                                  : "",
                                   }}
                              >
                                   {" "}
                                   {languageVersion.step_8_best}
                              </span>
                         </Row>}
                         {reduxState?.propertyResults?.data?.length >
                         0 ? null : reduxState.isLoading ? null : isTextShow &&  (
                              <div
                                   className="quick-property-no-result"
                                   style={{
                                        fontSize: "15px",
                                        fontFamily:
                                             languageVersion.id === 1
                                                  ? "NotoSansArabic-Bold"
                                                  : "",
                                   }}
                              >
                                   {" "}
                                   {languageVersion.not_found}
                              </div>
                         )}
                         <Row className="search-property-row">
                         {reduxState.isLoading ? <SkeletonCard count={8} />
                         :
                              reduxState?.propertyResults?.data?.map((project, index) => {
                                   return (
                                        <Col
                                             // lg={12}
                                             // md={12}
                                             // sm={24}
                                             style={{
                                                  fontFamily: "Mizan-AR LT-Bold",
                                                  //display: index > 3 ? "none" : null,
                                             }}
                                             className={`search-property-col ${
                                                  width > 320 &&
                                                  width < 768 &&
                                                  index === 3
                                                       ? "mob-true"
                                                       : ""
                                             }`}
                                        >
                                             <img
                                                  src={project.images[0]}
                                                  className="property-picture"
                                             ></img>
                                             <div className="search-property-content">
                                                  <>
                                                       <div
                                                            className="property-project"
                                                            style={{
                                                                 fontFamily:
                                                                      languageVersion.id ===
                                                                      1
                                                                           ? "NotoSansArabic-Bold"
                                                                           : "",
                                                            }}
                                                       >
                                                            {project.title}
                                                       </div>
                                                       <div
                                                            className="property-project-name"
                                                            style={{
                                                                 fontFamily:
                                                                      languageVersion.id ===
                                                                      1
                                                                           ? "NotoSansArabic-Bold"
                                                                           : "",
                                                            }}
                                                       >
                                                            {project.type}
                                                       </div>
                                                       <div
                                                            className="property-project-city"
                                                            style={{
                                                                 fontFamily:
                                                                      languageVersion.id ===
                                                                      1
                                                                           ? "NotoSansArabic-Regular"
                                                                           : "",
                                                            }}
                                                       >
                                                            {project.city}
                                                       </div>
                                                       <div className="property-project-address">
                                                            <img
                                                                 src={locationicon}
                                                                 style={{
                                                                      width: "10px",
                                                                      height: "12px",
                                                                      fontFamily:
                                                                           languageVersion.id ===
                                                                           1
                                                                                ? "NotoSansArabic-Regular"
                                                                                : "",
                                                                 }}
                                                            />
                                                            &nbsp; {project.location}
                                                       </div>
                                                       <div className="property-project-price">
                                                            <span className="dollersign-span">
                                                                 $
                                                            </span>
                                                            {project.price}
                                                       </div>
                                                       <div
                                                            className="property-project-info"
                                                            style={{
                                                                 direction:
                                                                      languageVersion.id ===
                                                                      1
                                                                           ? "rtl"
                                                                           : null,
                                                                 fontFamily:
                                                                      languageVersion.id ===
                                                                      1
                                                                           ? "NotoSansArabic-Regular"
                                                                           : "",
                                                            }}
                                                       >
                                                           <div className="listing-info-new-div">
                                                           <hr />
                                                           <img
                                                                src={bed}
                                                                className="property-project-info-icons"
                                                                style={{ border: "0" }}
                                                           ></img>
                                                           <span>
                                                                &nbsp;{" "}
                                                                {languageVersion.beds}{" "}
                                                                &nbsp;{" "}
                                                           </span>
                                                           <img
                                                                style={{
                                                                     borderLeft:
                                                                          languageVersion.id ===
                                                                          1
                                                                               ? "0px"
                                                                               : null,
                                                                     borderRight:
                                                                          languageVersion.id ===
                                                                          1
                                                                               ? "#D3DEE8 solid 1px"
                                                                               : null,
                                                                }}
                                                                src={bath}
                                                                className="property-project-info-icons"
                                                           ></img>
                                                           <span>
                                                                &nbsp;{" "}
                                                                {languageVersion.baths}{" "}
                                                                &nbsp;{" "}
                                                           </span>
                                                           <img
                                                                style={{
                                                                     borderLeft:
                                                                          languageVersion.id ===
                                                                          1
                                                                               ? "0px"
                                                                               : null,
                                                                     borderRight:
                                                                          languageVersion.id ===
                                                                          1
                                                                               ? "#D3DEE8 solid 1px"
                                                                               : null,
                                                                               
                                                                }}
                                                                src={bedroom}
                                                                className="property-project-info-icons"
                                                           ></img>
                                                           <span>
                                                                {" "}
                                                                &nbsp;{" "}
                                                                {
                                                                     languageVersion.rooms
                                                                }{" "}
                                                                &nbsp;
                                                           </span>
                                                           <img
                                                                style={{
                                                                     borderLeft:
                                                                          languageVersion.id ===
                                                                          1
                                                                               ? "0px"
                                                                               : null,
                                                                     borderRight:
                                                                          languageVersion.id ===
                                                                          1
                                                                               ? "#D3DEE8 solid 1px"
                                                                               : null,
                                                                }}
                                                                src={sqyard}
                                                                className="property-project-info-icons"
                                                           ></img>
                                                           <span>
                                                                &nbsp; {project.net_area}{" "}
                                                                &nbsp;
                                                           </span>
                                                           </div>
                                                       </div>
                                                  </>
                                                  {/* <Button
                      style={{
                        fontSize: "13px",
                        fontFamily: "NotoSansArabic-Bold",
                        marginBottom: '3px',
                        display: 'flex'
                      }}
                      onClick={
                        () => window.open(`https://wa.me/${reduxState.phone_number}?text=${project.title}
                        \n${project.type}
                        \n${project.price}`)}
                      className="next-step"
                    >
                      Share via Whatsapp
                    </Button> */}
                                             </div>
                                        </Col>
                                   );
                              })}
                         </Row>
                         <div className="blur"></div>
                    </div>
               </div>
          </div>
     );
};
const mapStateToProps = (state) => {
     return {
          languageVersion: state.languageVersion,
     };
};
export default connect(mapStateToProps)(BestProperties);
