import React, { useEffect } from "react";
import "../../assets/css/styles.scss";
import { Row, Col } from "antd";
import CSR from "../../assets/img/mustafa.jpg";
import "react-phone-input-2/lib/style.css";
import { connect, useDispatch } from "react-redux";
import { propertyResults } from "../../redux/actions";

const CustomerSupport = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(propertyResults([]));
  }, []);
  const { languageVersion } = props;
  return (
    <div className="main-select-step">
      <div className="main-content">
        <div
          className={`${
            languageVersion.id === 1
              ? "main-direction select-step-box"
              : "select-step-box"
          }`}
        >
          <Row
            className="select-your-type"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {languageVersion.step_7_our}&nbsp;
            <span
              className="quick-property-step-heading"
              style={{
                fontFamily:
                  languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
              }}
            >
              {languageVersion.step_7_customer}
            </span>
          </Row>
          <Row
            className="mobile-quick-property-description"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {languageVersion.step_7_mobile_description}
          </Row>
          <Row
            className="quick-property-description"
            justify="start"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {" "}
            {languageVersion.step_7_description}
          </Row>
          <Row>
            <div
              className="csr-bottom-heading"
              style={{
                fontFamily:
                  languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
              }}
            >
              <span
                style={{
                  fontFamily:
                    languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
                }}
              >
                {languageVersion.step_7_ladel}{" "}
              </span>{" "}
              {languageVersion.step_7_from_team}
            </div>
          </Row>
          <Row className="csr-details">
            <Col>
              <img className="csr-img" src={CSR}></img>
            </Col>
            <Col className="csr-info-content">
              <div
                className="csr-name"
                style={{
                  fontFamily:
                    languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
                }}
              >
                <span
                  style={{
                    fontFamily:
                      languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
                  }}
                >
                  {languageVersion.step_7_ladel}
                </span>{" "}
                {languageVersion.step_7_bekulmez}
              </div>
              <div
                className="csr-position"
                style={{
                  fontFamily:
                    languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
                }}
              >
                {languageVersion.step_7_sales}
              </div>
              <div
                className="csr-text"
                style={{
                  fontFamily:
                    languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
                }}
              >
                {languageVersion.csr_description}
              </div>
              {/* <div
                className="csr-email"
                style={{ direction: languageVersion.id === 1 ? "rtl" : null }}
              >
                <span style={{ fontWeight: "600" }}>
                  {languageVersion.step_7_email}
                </span>{" "}
                <a href="mailto:ladelbukulmez@qpt.com">ladelbukulmez@qpt.com</a>
              </div> */}
              <div className="csr-number">
                {languageVersion.id === 1 ? (
                  <>
                    <a href="tel:+905417649174">
                      <span dir="ltr">+90 541 764 91 74</span>
                    </a>
                    <span
                      className="phoneSpan"
                      id="numberSpan"
                      dir="ltr"
                      style={{ fontWeight: "600" }}
                    >
                      {languageVersion.step_7_phone}
                    </span>{" "}
                  </>
                ) : (
                  <>
                    <span
                      className="phoneSpan"
                      dir="ltr"
                      style={{ fontWeight: "600" }}
                    >
                      {languageVersion.step_7_phone}
                    </span>{" "}
                    <a href="tel:+905417649174">
                      <span dir="ltr">+90 541 764 91 74</span>
                    </a>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    languageVersion: state.languageVersion,
  };
};
export default connect(mapStateToProps)(CustomerSupport);
