import React, { useEffect } from "react";
import "../../assets/css/styles.scss";
import sitelogowhite from "../../assets/img/qptlogoright.svg";
import belowdashe from "../../assets/img/belowdashe.png";
import double_qoute from "../../assets/img/double_qoute.png";
import active from "../../assets/img/active.svg";
import Vector from "../../assets/img/Vector.png";
import { connect } from "react-redux";
function SelectSteps(props) {
  const { pageCount, languageVersion } = props;
  const data = [
    {
      name: languageVersion.step_1,
    },
    {
      name: languageVersion.step_2,
    },
    {
      name: languageVersion.step_3,
    },
    {
      name: languageVersion.step_4,
    },
    {
      name: languageVersion.step_5,
    },
    {
      name: languageVersion.step_6,
    },
    {
      name: languageVersion.step_7,
    },
  ];
  return (
    <div
      className={`${languageVersion.id === 1 ? "main-direction selectsteps" : "selectsteps"
        }`}
    >
      <div className="left-selection-top">
        <div>
          <img
            src={sitelogowhite}
            className="site-logo"
            alt="Quick Property Turkey Logo"
          ></img>
          {data.map((val, index) => (
            <>
              <div
                className="select-type"
                style={{ opacity: index < pageCount ? "1" : null }}
              >
                <img src={active} className="active-rectangle"></img>

                <div
                  className="type"
                  style={{
                    fontFamily:
                      languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
                  }}
                >
                  {val.name}{" "}
                </div>
              </div>
              {index < 6 ? (
                <div
                  className="dashes"
                  style={{ opacity: index < pageCount ? "1" : null }}
                >
                  <img
                    src={belowdashe}
                    className="below-dash"
                    alt="Below Dash"
                  ></img>
                  <img
                    src={belowdashe}
                    className="below-dash"
                    alt="Below Dash"
                  ></img>
                </div>
              ) : null}
            </>
          ))}
        </div>
        <div className="left-selection-bottom">
          <div className="keynote">
            <div className="comma">
              <img src={double_qoute} />
            </div>
            <div>
              <img src={Vector} className="vector" />
            </div>
            <div
              className="keynote-text-heading"
              style={{
                fontStyle: languageVersion.id === 1 ? "normal" : null,
                fontFamily:
                  languageVersion.id === 1 ? "NotoSansArabic-Bold" : languageVersion.id === 2 ? "Montserrat Alternates-Bold" : null
              }}
            >
              {languageVersion.nav_question}
            </div>
            <div
              className="keynote-text"
              style={{
                fontStyle: languageVersion.id === 1 ? "normal" : null,
                lineHeight: languageVersion.id === 2 ? "1.3" : null,
                fontFamily:
                  languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
                  paddingBottom:languageVersion.id===2? "13px":null,
              }}
            >
              {languageVersion.nav_answer}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    languageVersion: state.languageVersion,
  };
};
export default connect(mapStateToProps)(SelectSteps);
