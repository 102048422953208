import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/styles.scss";
import { Row, Col, Checkbox, Button } from "antd";
import { Slider } from "antd";
import "antd/dist/antd.css";
import useWindowSize from "../Hooks/windowSize";
import { connect } from "react-redux";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { storeBudget } from "../../redux/actions";

const price = {
  500000: {
    style: {
      left: "23px",
    },
    label: <strong>$50,000</strong>,
  },
  250000000: "$250M",
  500000000: "$500M",
  750000000: "$750M",
  1000000000: "$1B",
};
const mobPrice = {
  50000: {
    style: {
      left: "23px",
    },
    label: <strong>$50,000</strong>,
  },
  250000000: "",
  500000000: "$500M",
  750000000: "",
  1000000000: "$1B",
};

const SelectYourBudget = (props) => {
  const dispatch = useDispatch();
  const budgetRetainData = useSelector((state) => state.budgetRetainData);
  const sliderRef = useRef(null);
  const { languageVersion, pageCount, handleApiData } = props;

  const [slideValue, setSlideValue] = useState([50000, 5000000]);
  const [width, height] = useWindowSize();
  const handleSlider = async (value) => {
    const first = value[0].toString().replaceAll("$", "");
    const second = value[1].toString().replaceAll("$", "");
    await dispatch(
      storeBudget([first.match(/\d/g).join(""), second.match(/\d/g).join("")])
    );
    setSlideValue([first.match(/\d/g).join(""), second.match(/\d/g).join("")]);
  };
  useEffect(() => {
    handleApiData(slideValue.toString(), pageCount);
  }, [slideValue]);

  useEffect(() => {
    setSlideValue(budgetRetainData);
  }, []);

  return (
    <div className="main-select-step">
      <div className="main-content">
        <div
          className={`${
            languageVersion.id === 1
              ? "main-direction select-step-box"
              : "select-step-box"
          }`}
        >
          <Row
            className="select-your-type"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {languageVersion.step_2_select}&nbsp;
            <span
              className="quick-property-step-heading"
              style={{
                fontFamily:
                  languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
              }}
            >
              {languageVersion.step_5_budget}
            </span>
          </Row>
          <Row
            className="mobile-quick-property-description"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {languageVersion.step_5_mobile_description}
          </Row>
          <Row
            className="quick-property-description"
            justify="start"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {" "}
            {languageVersion.step_5_description}
          </Row>
          <Row
            className="choose-budget-heading"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Medium" : "",
            }}
          >
            {languageVersion.planning}
          </Row>
          <div
            className="mobile-heading"
            style={{
              fontFamily: languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
            }}
          >
            {languageVersion.select_your_budget}
          </div>

          <div className="budget-range-div">
            <div>
              <label
                className="budget-range-from"
                style={{
                  fontFamily:
                    languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
                }}
              >
                {languageVersion.step_5_from}{" "}
                <input
                  maxLength={9}
                  autoComplete="off"
                  className="budget-input-field"
                  name="name"
                  onChange={(e) => {
                    handleSlider([e.target.value, slideValue[1]]);
                  }}
                  placeholder="$"
                  value={`$${slideValue[0]}`}
                />
              </label>
            </div>
            <div>
              <label
                className="budget-range-from"
                style={{
                  fontFamily:
                    languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
                }}
              >
                {languageVersion.step_5_to}{" "}
                <input
                  maxLength={9}
                  autoComplete="off"
                  className="budget-input-field"
                  name="name"
                  onChange={(e) => {
                    handleSlider([slideValue[0], e.target.value]);
                  }}
                  placeholder="$"
                  value={`$${slideValue[1]}`}
                  type="text"
                />
              </label>
            </div>
          </div>
          <div className="slider">
            <Slider
              ref={sliderRef}
              value={slideValue}
              min={50000}
              max={1000000000}
              onChange={handleSlider}
              range={true}
              marks={width >= 320 && width < 767 ? mobPrice : price}
              step={5000000}
              tooltipVisible={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    languageVersion: state.languageVersion,
  };
};
export default connect(mapStateToProps)(SelectYourBudget);
