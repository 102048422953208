import React from "react";
import arrow from "../../assets/img/arrow.png";
import sitelogo from "../../assets/img/qptlogomobile2.png";
import { Row, Col } from "antd";
import { connect } from "react-redux";

const Header = (props) => {
  const { pageCount, languageVersion } = props;
  return (
    <div className="header-section">
      {
        <div span={8} className="mobilescreen-logo">
          <img className="mobilescreen-logo" src={sitelogo} alt="Logo"></img>
        </div>
      }
      {pageCount !== 9 && (
        <div
          span={10}
          className="step-number"
          style={{
            color: pageCount > 7 ? "white" : "#A4AEB4",
            fontFamily:
              languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
          }}
        >
          {languageVersion.header_step} {pageCount} {languageVersion.header_of}{" "}
          7
        </div>
      )}
      <div
        span={14}
        className="have-trouble"
        style={{
          fontFamily: languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
        }}
      >
        {languageVersion.header_trouble}&nbsp;{" "}
        <span
          className="get-help"
          style={{ order: languageVersion.id === 1 ? "1" : null }}
        >
          {languageVersion.header_help} <img src={arrow} alt="Arrow Icon"></img>
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    languageVersion: state.languageVersion,
  };
};
export default connect(mapStateToProps)(Header);
