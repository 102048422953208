import english from "../json/english.json";
import * as Actions from "./actions";

const initalState = {
  languageVersion: english,
  paramsData: [],
  retainData: [],
  cityRetainData: [],
  paymentMethodRetainData: undefined,
  budgetRetainData: [50000, 5000000],
  phone_number: undefined,
  whatsapp_number: undefined,
  email_address: undefined,
  isLoading: false,
  propertyResults: [],
  page: 1,
  isEmptyResults: false,
  isCitizenShipEnabled: 0,
  isFirstHandProject: 0,
  isFirstLoading: false
};

const rootReduce = (state = initalState, action) => {
  
  switch (action.type) {
       case Actions.SET_LANGUAGE_VERSION:
            return { ...state, languageVersion: action.payload };
       case Actions.API_Data:
            return { ...state, paramsData: action.payload };
       case Actions.Retain_Data:
            return { ...state, retainData: action.payload };
       case Actions.Retain_City:
            return { ...state, cityRetainData: action.payload };
       case Actions.Retain_Payment_Method:
            return { ...state, paymentMethodRetainData: action.payload };
       case Actions.Retain_Budget:
            return { ...state, budgetRetainData: action.payload };
       case Actions.Retain_ContactNo:
            return { ...state, phone_number: action.payload };
       case Actions.Retain_Whatsapp:
            return { ...state, whatsapp_number: action.payload };
       case Actions.Retain_Email:
            return { ...state, email_address: action.payload };
       case Actions.Loading:
            return { ...state, isLoading: action.payload };
       case Actions.Property_Results:
            return { ...state, propertyResults: action.payload };
       case Actions.Current_Page:
            return { ...state, page: action.payload };
       case Actions.isEmptyResults:
            return { ...state, isEmptyResults: action.payload };
       case Actions.isCitizenship:
            return { ...state, isCitizenShipEnabled: action.payload };
       case Actions.isFirstHandProject:
            return { ...state, isFirstHandProject: action.payload };
       case Actions.isFirstLoading:
            return { ...state, isFirstLoading: action.payload };
       case Actions.ResetState:
            return {
                 ...state,
                 paramsData: [],
                 retainData: [],
                 cityRetainData: [],
                 paymentMethodRetainData: undefined,
                 budgetRetainData: [50000, 500000],
                 phone_number: undefined,
                 whatsapp_number: undefined,
                 email_address: undefined,
                 isLoading: false,
                 propertyResults: [],
                 page: 1,
                 isEmptyResults: false,
                 isCitizenShipEnabled: 0,
                 isFirstHandProject: 0,
                 isFirstLoading: false,
            };
       default:
            return state;
  }
};

// const storeState = {
//   paramsData: "",
// };
// export const dataFromApi = (state = storeState, action) => {
//   switch (action.type) {
//     case Actions.API_Data:
//       return { state, paramsData: action.payload };
//   }
// };

export default rootReduce;
