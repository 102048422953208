import React, { useState, useRef, Component, useEffect } from "react";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import SelectLanguage from "../../components/Selectlanguage";
import SelectPropertyType from "../../components/SelectPropertyType";
import SelectSteps from "../../components/SelectSteps";
import SelectCity from "../../components/SelectCity";
import SelectPaymentMethod from "../../components/SelectPaymentMethod";
import SelectYourBudget from "../../components/SelectYourBudget/SelectYourBudget.js";
import ContactDetails from "../../components/ContactDetails";
import CustomerSupport from "../../components/CustomerSupport";
import BestProperties from "../../components/BestProperties";
import Header from "../../components/Header";
import FooterButtons from "../../components/Footer";
import Carousel from "../../components/Carousel";
import useWindowSize from "../../components/Hooks/windowSize";
import Loader from "../../components/loader";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoadingChange,
  propertyResults,
  setIsEmptyResults,
} from "./../../redux/actions";
import { axios } from "axios";

const Main = (props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.isLoading);
  const [pageCount, setPageCount] = useState(1);
  const [statChange, setStateChange] = useState(false);
  const [validate, setValidate] = useState(false);
  const [PropertyType, setPropertyType] = useState();
  const [CityType, setCityType] = useState();
  const [PaymentMethodType, setPaymentMethodType] = useState();
  const [selectBudgetRange, setselectBudgetRange] = useState();
  const [contacts, setcontacts] = useState();



  const [viewMore, setViewMore] = useState(false);
  const [width, height] = useWindowSize();
  const [retainedData, setretainedData] = useState();
  const reduxState = useSelector((state) => state);

  // handleRetainData=()=>{
  // }

  const handleValidation = (value) => {
    setValidate(value);
  };

  let apiParams = {
    PropertyTypeArray: PropertyType,
    CityTypeArray: CityType,
    PaymentMethodTypeArray: PaymentMethodType,
    budgetRangeArray: selectBudgetRange,
    ContactDetailsArray: contacts,
  };


  let postApiParams = {
    phone: reduxState.phone_number,
    whatsapp: reduxState.whatsapp_number,
    language: reduxState.languageVersion?.type,
    real_estate_type:PropertyType&& PropertyType.join(", "),
    citizenship_eligible: reduxState.isCitizenShipEnabled,
    first_hand_project: reduxState.isFirstHandProject,
    city: CityType&&CityType.join(", "),
    method: PaymentMethodType,
    minimum_investment: reduxState.budgetRetainData[1],
    maximum_investment: reduxState.budgetRetainData[0],
    email: reduxState.email_address,
  };
  console.log("========apidata =====",postApiParams)
  const handleApiData = (data, page) => {
    if (page === 2) {
      // apiParams.propertyTypes = data;
      setPropertyType(data);
    } else if (page === 3) {
      console.log("=====data====",data);
      setCityType(data);
    } else if (page === 4) {
      setPaymentMethodType(data);
    } else if (page === 5) {
      setselectBudgetRange(data);
    } else if (page === 6) {
      setcontacts(data);
    }
  };

  const handlePageCountFooter = (val) => {
    handlePageCount(val);
  };
  const handleViewMore = () => {
    setViewMore(true);
  };
  const handlePageCount = (val) => {
    setPageCount(val);
  };
  return (
    <>
      {/* {isLoading ?  <Loader /> : null}       {/* <DeviceOrientation lockOrientation="landscape" className="rotate-window" > */}
      {/* Will only be in DOM in landscape */}

      {/* Will stay in DOM, but is only visible in portrait */}
      {/* <Orientation
          orientation={width > 320 && width < 916 ? "portrait" : "landscape"}
          alwaysRender={false}
          className="rotate-window"
         >  */}
      <div className="main">
        <div className="main-left">
          <SelectSteps pageCount={pageCount} />
        </div>
        <div className="main-right">
          <Header pageCount={pageCount} />
          {pageCount === 1 ? (
            <>
              <SelectLanguage
                handlePageCount={handlePageCount}
                setStateChange={() => setStateChange(true)}
                pageCount={pageCount}
              />
            </>
          ) : pageCount === 2 ? (
            <>
              <SelectPropertyType
                handlePageCount={handlePageCount}
                setStateChange={() => setStateChange(!statChange)}
                pageCount={pageCount}
                handleValidation={handleValidation}
                handleApiData={handleApiData}
                retainedData={retainedData}
              />
            </>
          ) : pageCount === 3 ? (
            <>
              <SelectCity
                handlePageCount={handlePageCount}
                setStateChange={() => setStateChange(!statChange)}
                handleValidation={handleValidation}
                pageCount={pageCount}
                handleApiData={handleApiData}
              />
            </>
          ) : pageCount === 4 ? (
            <>
              <SelectPaymentMethod
                handlePageCount={handlePageCount}
                pageCount={pageCount}
                handleApiData={handleApiData}
                handleValidation={handleValidation}
              />
            </>
          ) : pageCount === 5 ? (
            <>
              <SelectYourBudget
                handlePageCount={handlePageCount}
                pageCount={pageCount}
                handleApiData={handleApiData}
              />
            </>
          ) : pageCount === 6 ? (
            <>
              <ContactDetails
                validate={validate}
                handlePageCountFooter={handlePageCountFooter}
                handlePageCount={handlePageCount}
                pageCount={pageCount}
                handleApiData={handleApiData}
                handleValidation={handleValidation}

              />
            </>
          ) : pageCount === 7 ? (
            <>
              <CustomerSupport
                handlePageCount={handlePageCount}
                pageCount={pageCount}
                handleApiData={handleApiData}
              />
            </>
          ) : pageCount === 8 ? (
            <>
              <BestProperties
                handlePageCount={handlePageCount}
                pageCount={pageCount}
                apiParams={apiParams}
                viewMore={viewMore}
              />
            </>
          ) : null}
          <Carousel pageCount={pageCount} />
          <FooterButtons
            pageCount={pageCount}
            validate={validate}
            postApiParams={postApiParams}
            handlePageCountFooter={handlePageCountFooter}
            handleViewMore={handleViewMore}
          />
        </div>
      </div>
      {/* </Orientation>
      </DeviceOrientation> */}
    </>
  );
};

export default Main;
