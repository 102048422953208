import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Routes from "./routes";
import 'antd/dist/antd.css';
import { Provider } from 'react-redux';
import store from './redux/store'
import { SkeletonTheme } from "react-loading-skeleton";

store.subscribe(()=> console.log("redux store",store.getState())  )
ReactDOM.render(
  
  <SkeletonTheme baseColor="#e6e6e6" highlightColor="#cccccc">
    <Provider store={store}>
      <Routes />
    </Provider>
  </SkeletonTheme>,
  document.getElementById("root")
);

