import React, { useState } from "react";
import "../../assets/css/styles.scss";
import { Row, Col, Checkbox, Button } from "antd";
import { ReactComponent as RussianIcon } from "../../assets/img/RussianIcon.svg";
import { ReactComponent as ArabicIcon } from "../../assets/img/ArabicIcon.svg";
import { ReactComponent as EnglishIcon } from "../../assets/img/EnglishIcon.svg";
import { useDispatch } from "react-redux";
import { resetStateWhenLangSelects, setLanguageVersion } from "../../redux/actions";
import english from "../../json/english.json";
import arabic from "../../json/arabic.json";
import russian from "../../json/russian.json";
import useWindowSize from "../Hooks/windowSize";
import { connect } from "react-redux";

const SelectLanguage = (props) => {
  const { languageVersion } = props;
  const dispatch = useDispatch();
  const [select, setSelect] = useState(false);
  const [selectedVal, setSelectedValue] = useState(languageVersion.id);
  const [width, height] = useWindowSize();

  const data = [
    {
      languageIcon: EnglishIcon,
      // className={`${selectedVal === true ? "asadd" : null}`} />
      languageName: "English",
    },
    {
      languageIcon: ArabicIcon,
      languageName: "Arabic",
    },
    {
      languageIcon: RussianIcon,
      languageName: "Russian",
    },
  ];
  const handleCheckbox = () => {
    setSelect(!select);
  };

  const handleOnClick = (val) => {
    setSelectedValue(val);
    if (val == 0) {
      dispatch(setLanguageVersion(english));
      dispatch(resetStateWhenLangSelects());
    } else if (val == 1) {
      dispatch(setLanguageVersion(arabic));
      dispatch(resetStateWhenLangSelects());

    } else {
      dispatch(setLanguageVersion(russian));
      dispatch(resetStateWhenLangSelects());

    }
  };
  return (
    <div
      className={`${
        languageVersion.id === 1
          ? "main-for-arabic main-select-step"
          : "main-select-step"
      }`}
    >
      <div className="main-content">
        <div
          className={`${
            languageVersion.id === 1
              ? "main-direction select-step-box"
              : "select-step-box"
          }`}
          style={{
            fontFamily:
              languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
          }}
        >
          <Row
            className="select-your-type"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {languageVersion.step_1_welcome}&nbsp;
            <span
              className="quick-property-step-heading"
              style={{
                fontFamily:
                  languageVersion.id === 1 ? "NotoSansArabic-ExtraBold" : "",
              }}
            >
               { width >767 && languageVersion.id===1 ?  languageVersion.company_name_web:languageVersion.company_name}
            </span>
          </Row>
          <Row
            className="mobile-quick-property-description"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {languageVersion.step_1_mobile_description}
          </Row>
          <Row
            className="quick-property-description"
            justify="start"
            style={{
              fontFamily:
                languageVersion.id === 1 ? "NotoSansArabic-Regular" : "",
            }}
          >
            {" "}
            {languageVersion.step_1_description}
          </Row>
          <Row
            className="choose-step interested-property"
            style={{
              fontFamily: languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
            }}
          >
            {languageVersion.step_1_select}
          </Row>
          <Row className="select-step-row">
            {data.map((language, index) => {
              return (
                <>
                  <Col
                    className={`${
                      width > 900 && width < 1025
                        ? "ipad-pro "
                        : "choose-step-box"
                    }
                      `}
                    style={{
                      fontFamily:
                        languageVersion.id === 1 ? "NotoSansArabic-Bold" : "",
                      backgroundColor:
                        width > 280 && width <676 && selectedVal === index
                          ? "#FF810F"
                          : null,
                      border:
                        selectedVal === index ? "#FF810F 1.5px solid" : null,
                    }}
                    onClick={() => {
                      handleOnClick(index);
                    }}
                  >
                    <Checkbox
                      className="step-checkbox"
                      checked={selectedVal === index ? true : false}
                    />
                    {/* {selectedVal === index && setD(true)} */}
                    <div className="type">
                      <div>
                        <language.languageIcon
                          className={
                            selectedVal === index ? "checked-type" : "type-icon"
                          }
                        />
                        {/* {language.languageIcon} */}
                        {/* <img
                        src={language.languageIcon}
                        className="type-image"
                        alt="Family Logo"
                      ></img> */}
                      </div>
                      <div
                        className="choose-option-name"
                        style={{
                          color:
                            width > 280 && width < 676 && selectedVal === index
                              ? "#fff"
                              : selectedVal === index
                              ? "#FF810F"
                              : null,
                        }}
                      >
                        {language.languageName}
                      </div>
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    languageVersion: state.languageVersion,
  };
};

export default connect(mapStateToProps)(SelectLanguage);
