import axios from "axios";
import store from '../redux/store'

const token = 'EAAMiuLCfkvYBAImZAe5v9zASAIZCLX2cXAqqJqrZCIsBGgxPZBgMMZAXCzfNZAJaGSCtYQxKQMABKCRwZBe5nrpkfj6b9NBZC8WcZAVWU8HDW1NFFWm3o6Q2FZCIsWngXPZBHqMZA3VajtEyaxZAi2nKv2c0rHzZAaZCNcFnKZBP3fmHVJao6MlNTyzsgwQO';

let config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }

 
  

function sendToWhatsapp(results){
    const langV = store.getState().languageVersion?.id === 0 ? 'r' : store.getState().languageVersion?.id === 1 ? 'ra' : store.getState().languageVersion?.id === 2 ? 'rr' : 'r';
    const lang = store.getState().languageVersion?.id === 0 ? 'en' : store.getState().languageVersion?.id === 1 ? 'ar' : store.getState().languageVersion?.id === 2 ? 'ru' : 'en';
    
    var arr = [];
    for(let i=0; i<=results.length; i++){
        if(i<8){
            arr.push({
                "type": "text",
                "text": results[i]?.title ? results[i]?.title : ''
            });
            arr.push({
                "type": "text",
                "text": results[i]?.type ? results[i]?.type : ''
            });
            arr.push({
                "type": "text",
                "text": results[i]?.city ? results[i]?.city : ''
            });
            arr.push({
                "type": "text",
                "text": results[i]?.price ? results[i]?.price : ''
            });
        }
    }
    let data = {
        "messaging_product": "whatsapp",
        "to": `${store.getState().whatsapp_number}`,
        "type": "template",
        "template": {
           "name":  results.length === 2 ? `${langV}2` : results.length === 3 ? `${langV}3` : results.length === 4 ? `${langV}4` : results.length === 5 ? `${langV}5` :
           results.length === 6 ? `${langV}6` : results.length === 7 ? `${langV}7` : results.length >= 8 ? `${langV}8` : results.length === 1 ? `${langV}1` : 'r1',
           "language": {
               "code": `${lang}`,
               "policy": "deterministic"
           },
           "components": [
             {
               "type": "body",
               "parameters": [...arr]
             }
           ]
        }
    }
    console.log({whatsappData: data})
    return new Promise(function(resolve, reject) {
        axios.post('https://graph.facebook.com/v14.0/100105676202780/messages', data, config).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
            console.log({err: err, env: store.getState().whatsapp_number});
        })
      });
}


export const whatsappApi = {
    sendToWhatsapp
}